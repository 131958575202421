import React from 'react';
import { ImageCardProps } from '..';
import { Description, Label, StyledCTAButton, CTAEditorModal, Title, Wrapper } from './styles';
import usePageBlock from 'hooks/use-page-block';
import { useFocusArea } from 'hooks';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { getAdminBarWidth } from 'services/admin/selectors';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { useImageGalleryContext } from '../../ImageGalleryContext/ImageGalleryContext';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';

interface TextContentProps {
  imageCard: ImageCardProps;
}

type EditableStringPath = (
  'label' |
  'labelV2' |
  'title' |
  'titleV2' |
  'description' |
  'descriptionV2' |
  'cta.variant' |
  'cta.behavior' |
  'cta.text' |
  'cta.height.desktop' |
  'cta.height.mobile'
);

const TextContent: React.FC<TextContentProps> = ({ imageCard }) => {
  const isEditing = useSelector(isEditMode);
  const adminBarWidth = useSelector(getAdminBarWidth);
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);
  const [isFocused, setIsFocused] = React.useState(false);
  const {
    hasTextContent,
    imagesPerRow,
    editImageCard,
    showCta,
    showDescription,
    showLabel,
    showTitle,
    gridTextAlignment,
    imageLayout,
    background,
  } = useImageGalleryContext();

  const { handleChange } = usePageBlock<EditableStringPath, ImageCardProps>({
    item: imageCard,
    onChange: editImageCard,
  });

  const ref = useFocusArea({
    onExit: () => setIsFocused(false),
    active: isFocused,
  });

  const handleDisablePropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsFocused(true);
  };

  const renderLabel = () => {
    if (!showLabel) {
      return null;
    }

    const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && imageCard.labelV2);
    const label = useRawDataV2 ? imageCard.labelV2 : imageCard.label;
    const key = useRawDataV2 ? 'labelV2' : 'label';

    return (
      <Label
        imagesPerRow={imagesPerRow}
        blockTitleKey="ADMIN_LABEL"
        onChange={handleChange(key)}
        data={label}
        useRawDataV2={useRawDataV2}
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
        data-testid="image-card-label"
      />
    );
  };

  const renderTitle = () => {
    if (!showTitle) {
      return null;
    }

    const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && imageCard.titleV2);
    const title = useRawDataV2 ? imageCard.titleV2 : imageCard.title;
    const key = useRawDataV2 ? 'titleV2' : 'title';

    return (
      <Title
        imagesPerRow={imagesPerRow}
        blockTitleKey="ADMIN_LABEL_TITLE"
        onChange={handleChange(key)}
        data={title}
        useRawDataV2={useRawDataV2}
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
        data-testid="image-card-title"
        htmlTag="h3"
      />
    );
  };

  const renderDescription = () => {
    if (!showDescription) {
      return null;
    }

    const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && imageCard.descriptionV2);
    const description = useRawDataV2 ? imageCard.descriptionV2 : imageCard.description;
    const key = useRawDataV2 ? 'descriptionV2' : 'description';

    return (
      <Description
        imagesPerRow={imagesPerRow}
        blockTitleKey="ADMIN_LABEL_DESCRIPTION"
        onChange={handleChange(key)}
        useRawDataV2={useRawDataV2}
        data={description}
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
        data-testid="image-card-description"
      />
    );
  };

  const handleChangeBehavior = React.useCallback((behavior: ImageCardProps['cta']['behavior']) => {
    editImageCard({
      ...imageCard,
      cta: {
        ...imageCard.cta,
        behavior,
      },
    });
  }, [editImageCard, imageCard]);

  const renderCta = React.useCallback(() => {
    if (!showCta) {
      return null;
    }

    return (
      <CTAEditorModal
        modalTitleKey="ADMIN_LABEL_CALL_TO_ACTION"
        blockTitleKey="ADMIN_LABEL_CALL_TO_ACTION"
        onChangeVariant={handleChange('cta.variant')}
        onChangeBehavior={handleChangeBehavior}
        onChangeText={handleChange('cta.text')}
        onChangeDesktopHeight={handleChange('cta.height.desktop')}
        onChangeMobileHeight={handleChange('cta.height.mobile')}
        cta={imageCard.cta}
      >
        {(text) => (
          <StyledCTAButton
            variant={imageCard.cta.variant}
            imagesPerRow={imagesPerRow}
            containerBackgroundColor={background.color}
            height={imageCard.cta.height}
          >
            {text}
          </StyledCTAButton>
        )}
      </CTAEditorModal>
    );
  }, [showCta, background.color, imageCard.cta, handleChange, imagesPerRow, isEditing, adminBarWidth]);

  if (!hasTextContent) {
    return null;
  }

  return (
    <Wrapper
      // @ts-ignore
      ref={ref}
      isFocused={isFocused}
      onClick={handleDisablePropagation}
      alignment={gridTextAlignment}
      imageLayout={imageLayout}
    >
      {renderLabel()}
      {renderTitle()}
      {renderDescription()}
      {renderCta()}
    </Wrapper>
  );
};

export default TextContent;
